<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-row>
          <Drawer />
          <v-col
            cols="12"
            md="9"
            class="ml-auto pa-0"
          >
            <Header>
              <template v-slot:title>
                <span class="mr-3 text-sm-h4 white--text font-weight-bold">{{ $t('mes-photos') }}
                  <v-icon color="primary">
                    mdi-pin
                  </v-icon></span>
              </template>
            </Header>
            <v-alert
              v-if="suppression_photo"
              class="rounded-0"
              type="success"
            >
              {{ $t('photo-supprime') }}
            </v-alert>
            <v-row class="ma-1 ml-0 my-5">
              <v-col
                cols="12"
              >
                <div
                  v-if="user.custom_field_array"
                  v-fancybox:photos="{
                    on:{
                      done: (fancybox, slide)=>{
                        slide.$el.querySelector('.desepingler').addEventListener('click', event => {
                          supprimerPhoto(slide.$el.querySelector('.desepingler').getAttribute('img-url'))
                        });
                      }
                    }
                  }"
                  class="d-block"
                  :class="user.custom_field_array.ec_photos ? 'gallery' : ''"
                >
                  <template v-if="user.custom_field_array.ec_photos">
                    <div
                      v-for="(photo, index) in user.custom_field_array.ec_photos"
                      :key="index"
                    >
                      <v-img
                        class="d-inline-block photo elevation"
                        :data-caption="`<button class='desepingler v-btn' img-url='${photo.href}'>Désépingler</button>`"
                        :src="photo.src"
                        :data-src="photo.href"
                      />
                    </div>
                  </template>
                  <template v-else>
                    <v-alert
                      class="rounded-0"
                      type="info"
                    >
                      {{ $t('pas-de-photo') }} <a
                        class="white--text"
                        target="_blank"
                        :href="piscinelleUrl + ($i18n.locale == 'fr' ? '' : $i18n.locale+'/') + 'diaporama-photos-piscines/3?email=' + user.email"
                      >piscinelle.com</a>.
                    </v-alert>
                  </template>
                </div>
              </v-col>
            </v-row>
            <v-divider class="my-15 mx-5 big-divider" />
            <v-row class="ma-1 ml-0">
              <v-col
                cols="12"
                md="8"
              >
                <p class="text-h4">
                  {{ $t('inspirez-vous') }}
                </p>
                <p class="muted--text">
                  {{ $t('inspirez_vous_desc') }}
                </p>
                <a
                  :href="piscinelleUrl + ($i18n.locale == 'fr' ? '' : $i18n.locale+'/') + 'diaporama-photos-piscines/3?email=' + user.email"
                  target="_blank"
                >
                  <v-img
                    class="elevation"
                    :src="piscinelleUrl + 'upload/piscinelle-plus-de-50-reportages-home-orig.jpg'"
                  /></a>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Header from '@/components/Header'
import Drawer from '@/components/Drawer'

import { mapGetters } from 'vuex'

export default {
  name: 'Photos',
  components: {
    Header,
    Drawer
  },
  data () {
    return {
      piscinelleUrl: process.env.VUE_APP_PISCINELLE_URL,
      suppression_photo: false
    }
  },
  computed: {
    ...mapGetters([
      'user'
    ])
  },
  methods: {
    supprimerPhoto (photo) {
      this.$store.dispatch('supprimerPhoto', photo).then(() => {
        this.suppression_photo = true
      })
    }
  }
}
</script>
