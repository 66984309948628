var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('Drawer'),_c('v-col',{staticClass:"ml-auto pa-0",attrs:{"cols":"12","md":"9"}},[_c('Header',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{staticClass:"mr-3 text-sm-h4 white--text font-weight-bold"},[_vm._v(_vm._s(_vm.$t('mes-photos'))+" "),_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-pin ")])],1)]},proxy:true}])}),(_vm.suppression_photo)?_c('v-alert',{staticClass:"rounded-0",attrs:{"type":"success"}},[_vm._v(" "+_vm._s(_vm.$t('photo-supprime'))+" ")]):_vm._e(),_c('v-row',{staticClass:"ma-1 ml-0 my-5"},[_c('v-col',{attrs:{"cols":"12"}},[(_vm.user.custom_field_array)?_c('div',{directives:[{name:"fancybox",rawName:"v-fancybox:photos",value:({
                  on:{
                    done: function (fancybox, slide){
                      slide.$el.querySelector('.desepingler').addEventListener('click', function (event) {
                        _vm.supprimerPhoto(slide.$el.querySelector('.desepingler').getAttribute('img-url'))
                      });
                    }
                  }
                }),expression:"{\n                  on:{\n                    done: (fancybox, slide)=>{\n                      slide.$el.querySelector('.desepingler').addEventListener('click', event => {\n                        supprimerPhoto(slide.$el.querySelector('.desepingler').getAttribute('img-url'))\n                      });\n                    }\n                  }\n                }",arg:"photos"}],staticClass:"d-block",class:_vm.user.custom_field_array.ec_photos ? 'gallery' : ''},[(_vm.user.custom_field_array.ec_photos)?_vm._l((_vm.user.custom_field_array.ec_photos),function(photo,index){return _c('div',{key:index},[_c('v-img',{staticClass:"d-inline-block photo elevation",attrs:{"data-caption":("<button class='desepingler v-btn' img-url='" + (photo.href) + "'>Désépingler</button>"),"src":photo.src,"data-src":photo.href}})],1)}):[_c('v-alert',{staticClass:"rounded-0",attrs:{"type":"info"}},[_vm._v(" "+_vm._s(_vm.$t('pas-de-photo'))+" "),_c('a',{staticClass:"white--text",attrs:{"target":"_blank","href":_vm.piscinelleUrl + (_vm.$i18n.locale == 'fr' ? '' : _vm.$i18n.locale+'/') + 'diaporama-photos-piscines/3?email=' + _vm.user.email}},[_vm._v("piscinelle.com")]),_vm._v(". ")])]],2):_vm._e()])],1),_c('v-divider',{staticClass:"my-15 mx-5 big-divider"}),_c('v-row',{staticClass:"ma-1 ml-0"},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('p',{staticClass:"text-h4"},[_vm._v(" "+_vm._s(_vm.$t('inspirez-vous'))+" ")]),_c('p',{staticClass:"muted--text"},[_vm._v(" "+_vm._s(_vm.$t('inspirez_vous_desc'))+" ")]),_c('a',{attrs:{"href":_vm.piscinelleUrl + (_vm.$i18n.locale == 'fr' ? '' : _vm.$i18n.locale+'/') + 'diaporama-photos-piscines/3?email=' + _vm.user.email,"target":"_blank"}},[_c('v-img',{staticClass:"elevation",attrs:{"src":_vm.piscinelleUrl + 'upload/piscinelle-plus-de-50-reportages-home-orig.jpg'}})],1)])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }